.cv-sidebar-icons {
  height: 1.09375rem;
  font-size: 1.09375rem;
  text-align: center;
  transition: 0.3s;
  fill: currentColor;
  margin-right: 1.5rem;
  margin-left: 4px;
}

.c-sidebar-brand-full {
  padding: 10px;
}

.c-sidebar-nav-link {
  display: flex;
  justify-content: space-between;
}
