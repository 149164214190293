/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// Variable overrides
$custom-primary-color: #001B42;
$gray-base: #E5E5E5;
$body-color: $custom-primary-color;
$sidebar-hover-background: linear-gradient(99.21deg, #00ADEF -0.68%, #14347F 72.06%);
$font-family-sans-serif: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$sidebar-dark-nav-title-color: #fff;
$sidebar-dark-nav-link-color: #fff;
$sidebar-dark-nav-dropdown-indicator-color: #fff;

$primary-base:  $custom-primary-color;
$primary-100:   #eae9fb !default;
$primary-200:   #d6d2fb !default;
$primary-300:   #c1bcf4 !default;
$primary-400:   #ada5f1 !default;
$primary-500:   #988fed !default;
$primary-600:   #8478ea !default;
$primary-700:   #6f62e6 !default;
$primary-800:   #5b4ce2 !default;
$primary-900:   #4635df !default;

$primary-dark:  #1f1498 !default;
$primary:       $custom-primary-color;
$primary-50:    #988fed !default;
$primary-25:    #ccc7f6 !default;


$secondary-base:  #3c4b64 !default;

$secondary-100:   #ebedef !default;
$secondary-200:   #d8dbe0 !default;
$secondary-300:   #c4c9d0 !default;
$secondary-400:   #b1b7c1 !default;
$secondary-500:   #9da5b1 !default;
$secondary-600:   #8a93a2 !default;
$secondary-700:   #768192 !default;
$secondary-800:   #636f83 !default;
$secondary-900:   #4f5d73 !default;

$secondary-dark:  #212233 !default;
$secondary:       #3c4b64 !default;
$secondary-50:    #9da5b1 !default;
$secondary-25:    #ced2d8 !default;

$headings-color: $custom-primary-color;

// SIDEBAR OVERRIDES
$sidebar-dark-color: $custom-primary-color;
$sidebar-dark-bg: $custom-primary-color;
$sidebar-dark-nav-link-hover-bg: $sidebar-hover-background;

//HEADER OVERRIDES
$header-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$custom-primary-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$header-dark-toggler-hover-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$custom-primary-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$header-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$custom-primary-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
$header-light-toggler-hover-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$custom-primary-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");