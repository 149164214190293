// Here you can add other styles
.input-error {
  font-size: 12px;
  color: red;
  text-align: left;
  margin-top: 3px;
}

.action-buttons {
  float: right;
}